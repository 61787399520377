@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import '../../../node_modules/@mds/design-luna/src/assets/themes/light/theme.scss';
@import '../../../node_modules/@mds/icons8/src/assets/styles/style-mds.css';
@import '../../../node_modules/swagger-ui/dist/swagger-ui.css';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

body {
  display: flex;
  flex-direction: column;
}

#luna-main {
  min-height: 0;
}

// does not yet exist in primeflex
.min-w-25rem {
  min-width: 25rem;
}
